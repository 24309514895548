import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AppSettings } from 'app/shared/app.settings';


// declare const ATInternet: any;
// export type Tag = any;

export interface PageInfo {
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  customObject?: any;
}

export interface ClickInfo {
  elem: any;
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  type: string;
  event?: any;
}

@Injectable()
export class TrackingService {

  // private tag: Tag;

  constructor() {
    if(this.isATIAvailable()) {
      // try {
      //   this.tag = new ATInternet.Tracker.Tag();
      // } catch (e) {
      //   console.log(e);
      // }
    }
  }

  isATIAvailable() : boolean {
    return AppSettings.ATI_TRACKING_ID != '' && environment.production;
  }

  // click(info: ClickInfo): boolean {
  //   if (this.isATIAvailable()) {
  //     return this.tag.click.send(info);
  //   } else {
  //     console.log('would track CLICK on live', info);
  //   }
  // }

  // clickListener(info: ClickInfo): void {
  //   if (this.isATIAvailable()) {
  //     this.tag.clickListener.send(info);
  //   } else {
  //     console.log('would track CLICKLISTENER on live', info);
  //   }
  // }

  // pageSend(info: PageInfo): void {
  //   if (this.isATIAvailable()) {
  //     this.tag.page.send(info);
  //   } else {
  //     console.log('would track PAGESEND on live', info);
  //   }
  // }

}
